import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    // connect() {
    //   alert('loaded js_utility');
    // }
  
  buildTime(event) {
    event.preventDefault();
    console.log('hit buildTime');

    const startTimeHourString = document.getElementById('daily_log_start_time_4i').value;
    const startTimeMinutesString = document.getElementById('daily_log_start_time_5i').value;

    const start_time_hour = parseInt(startTimeHourString, 10);
    const start_time_minutes = parseInt(startTimeMinutesString, 10);

    const endTimeHourString = document.getElementById('daily_log_end_time_4i').value;
    const endTimeMinutesString = document.getElementById('daily_log_end_time_5i').value;

    const end_time_hour = parseInt(endTimeHourString, 10);
    const end_time_minutes = parseInt(endTimeMinutesString, 10);
    
    const start_time = new Date();
    const end_time = new Date();

    start_time.setHours(start_time_hour, start_time_minutes, 0, 0);
    end_time.setHours(end_time_hour, end_time_minutes, 0, 0);

    const start_time_zone = event.currentTarget.dataset.startTimeZone
    const end_time_zone = event.currentTarget.dataset.endTimeZone
    const start_time_zone_offset = event.currentTarget.dataset.startTimeOffset
    const end_time_zone_offset = event.currentTarget.dataset.endTimeOffset

    const new_start_time_string = start_time.toLocaleString("en-US", { timeZone: start_time_zone, hour12: false });
    const new_end_time_string = end_time.toLocaleString("en-US", { timeZone: end_time_zone, hour12: false });

    // console.log(`new_start_time_string: ${ new_start_time_string }`)


    // pull out the new start time in correct zone
    // 1/2/2025, 06:00:00
    const start_date_string = new_start_time_string.split(', ')[0]
    const start_time_string = new_start_time_string.split(', ')[1]

    const new_start_time_year = start_date_string.split('/')[2]
    // console.log(`should be year: ${ new_start_time_year }`)
    const new_start_time_month = start_date_string.split('/')[0]
    // console.log(`should be month: ${ new_start_time_month }`)
    const new_start_time_day = start_date_string.split('/')[1]
    // console.log(`should be day: ${ new_start_time_day }`)
    const new_start_time_hour = start_time_string.split(':')[0]
    // console.log(`should be hour: ${ new_start_time_hour }`)
    const new_start_time_minute = start_time_string.split(':')[1]
    // console.log(`should be minute: ${ new_start_time_minute }`)




    // pull out the new end time in correct zone
    const end_date_string = new_end_time_string.split(', ')[0]
    const end_time_string = new_end_time_string.split(', ')[1]

    const new_end_time_year = end_date_string.split('/')[2]
    const new_end_time_month = end_date_string.split('/')[0]
    const new_end_time_day = end_date_string.split('/')[1]
    const new_end_time_hour = end_time_string.split(':')[0]
    const new_end_time_minute = end_time_string.split(':')[1]



    // YYYY-MM-DDTHH:mm:ss.sssZ
    // 2025-04-21T09:00:00.000-08:00
    // 2025-21-01T19:00:00.000-08:00

    const start_time_in_correct_zone = `${ new_start_time_year }-${ new_start_time_month.padStart(2, '0') }-${ new_start_time_day.padStart(2, '0') }T${ new_start_time_hour }:${ new_start_time_minute }:00.000${ start_time_zone_offset }:00`
    const end_time_in_correct_zone = `${ new_end_time_year }-${ new_end_time_month.padStart(2, '0') }-${ new_end_time_day.padStart(2, '0') }T${ new_end_time_hour }:${ new_end_time_minute }:00.000${ end_time_zone_offset }:00`



    // const split_start_times = new_start_time_string.split(', ')[1]
    // const new_st_hour = split_start_times.split(':')[0]
    // const new_st_minutes = split_start_times.split(':')[1]

    // const split_end_times = new_end_time_string.split(', ')[1]
    // const new_et_hour = split_end_times.split(':')[0]
    // const new_et_minutes = split_end_times.split(':')[1]

    // const st = new Date();
    // const et = new Date();

    // console.log(`start_time_in_correct_zone: ${ start_time_in_correct_zone }`)
    // console.log(`end_time_in_correct_zone: ${ end_time_in_correct_zone }`)


    const st = Date.parse(start_time_in_correct_zone);
    const et = Date.parse(end_time_in_correct_zone);

    // st.setHours(new_st_hour, new_st_minutes, 0, 0);
    // et.setHours(new_et_hour, new_et_minutes, 0, 0);

    // console.log(`shit sake`);
    console.log(`st: ${ st }`);
    console.log(`et: ${ et }`);

    // Calculate the difference in milliseconds
    const differenceInMs = et - st;

    // Handle cases where the end time is on the next day
    const adjustedDifference = differenceInMs >= 0 ? differenceInMs : differenceInMs + 24 * 60 * 60 * 1000;

    // Convert the difference to hours and minutes
    const hours = Math.floor(adjustedDifference / (1000 * 60 * 60));
    const minutes = Math.floor((adjustedDifference % (1000 * 60 * 60)) / (1000 * 60));

    console.log(`${ hours }h ${ minutes }m`);
    event.currentTarget.innerHTML = `${ hours }h ${ minutes }m`    
  }

}
