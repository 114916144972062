import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "input", "counter" ]
  static values = {
    countdown: String
  }

  // connect() {
  //   console.log('fuck off you prick');
  // }

  countCharacters(event) {
    if (this.countdownValue == 'true') {
      let maxlength = parseInt( event.currentTarget.maxLength );
      let characters = parseInt( event.currentTarget.value.length );
      this.counterTarget.innerText = (maxlength - characters);
    } else {
      let characters = event.currentTarget.value.length;
      this.counterTarget.innerText = characters;}
  }
}